import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { MultiSelectValueType } from "../../../../components/CheckboxFilterButton";
import CustomInputField from "../../../../components/CustomInputField";
import MultiSelectAutocomplete from "../../../../components/MultiSelectAutocomplete";
import PrimaryLoadingButton from "../../../../components/PrimaryLoadingButton";
import { QueryProps } from "./CustomCasePreview";
import CustomChipInput from "./CustomChipInput";

interface QueryInputProps {
  handleQuery(queryValue: QueryProps): void;
}

export default function QueryInputs({ handleQuery }: QueryInputProps) {
  const theme = useTheme();
  const [query, setQuery] = useState<QueryProps>({ text: "", role: "", pipeline_id: "", languages: [], redflags: [], sources: [] });
  const [languages, setlanguages] = useState<MultiSelectValueType[]>([]);
  const [sources, setSources] = useState<MultiSelectValueType[]>([]);
  // const [keywords, setKeywords] = useState<string[]>([]);
  const handleQueryText = (value: string) => {
    setQuery({ ...query, text: value });
  };

  const handleRole = (value: string) => {
    setQuery({ ...query, role: value });
  };

  const handleLanguage = (value: MultiSelectValueType[]) => {
    const newValues = value.map((data) => data.text);
    //setlanguages((prev) => [...prev, ...newValues]);
    setQuery({ ...query, languages: newValues });
  };

  const handleRedFlags = (value: string[]) => {
    setQuery({ ...query, redflags: value });
  };

  const handleSource = (value: MultiSelectValueType[]) => {
    let newValues = value.map((data) => data.text);
    if (newValues.includes("News Crawler")) {
      newValues.push("Indiatoday Crawler");
      newValues.push("BBC Crawler");
      newValues.push("CNN Crawler");
      newValues = newValues.filter((data) => data !== "News Crawler");
    }
    else{
      newValues = newValues.filter((data) => data !== "Indiatoday Crawler" && data !== "BBC Crawler" && data !== "CNN Crawler");
    }
    if (newValues.includes("Internet Crawler")) {
      newValues.push("News Crawler");
      newValues = newValues.filter((data) => data !== "Internet Crawler");
    }
    setQuery({ ...query, sources: newValues });
  };

  const languageList: MultiSelectValueType[] = [
    {
      id: "en",
      text: "English",
    },
    {
      id: "hi",
      text: "Hindi",
    },
    {
      id: "zh",
      text: "Chinese",
    },
  ];

  const sourceList: MultiSelectValueType[] = [
    {
      id: "news",
      text: "News Crawler",
    },
    {
      id: "x",
      text: "Twitter Crawler",
    },
    {
      id: "fb",
      text: "Facebook Crawler",
    },
    {
      id: "ig",
      text: "Instagram Crawler",
    },
    {
      id: "yt",
      text: "Youtube Crawler",
    },
    {
      id: "li",
      text: "Linkedin Crawler",
    },
    {
      id: "weibo",
      text: "Weibo Crawler",
    },
    {
      id: "internet",
      text: "Internet Crawler",
    }
  ];

  return (
    <Box textAlign="center">
      <Typography variant="semiBold" sx={{ color: theme.palette.text.default }}>
        Query Inputs
      </Typography>
      <Box textAlign="left">
        <Typography variant="h4" sx={{ color: theme.palette.text.tableHeader, my: 1.5 }}>
          Query
        </Typography>
        <CustomInputField
          value={query.text}
          required
          name="text"
          placeholder="Enter query"
          size="small"
          variant="outlined"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleQueryText(e.target.value)}
          autoComplete="off"
          sx={{ width: "100%" }}
        />
        <Typography variant="h4" sx={{ color: theme.palette.text.tableHeader, mt: 1.5, mb: 0.5 }}>
          Role
        </Typography>
        <CustomInputField
          value={query.role}
          required
          name="role"
          placeholder="Enter role"
          size="small"
          variant="outlined"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRole(e.target.value)}
          autoComplete="off"
          sx={{ width: "100%" }}
        />
        {/* <Typography
          variant="h4"
          sx={{
            color: theme.palette.text.tableHeader,
            my: 1.5,
          }}
        >
          Responsibilities
        </Typography>
        <MultiChipInputField placeholder="Enter comma separated list" separator={","} onValueChange={handleResponsiblity} /> */}
        <Typography variant="h4" sx={{ color: theme.palette.text.tableHeader, my: 1.5 }}>
          Language
        </Typography>
        <MultiSelectAutocomplete
          isRequired={true}
          options={languageList}
          placeholder="Select Language"
          handleAutoChange={(value: MultiSelectValueType[]) => handleLanguage(value)}
          selectionData={languages}
          isReset={true}
          size="small"
        />
        <Typography variant="h4" sx={{ color: theme.palette.text.tableHeader, my: 1.5 }}>
          Source
        </Typography>
        <MultiSelectAutocomplete
          isRequired={true}
          options={sourceList}
          placeholder="Select Source"
          handleAutoChange={(value: MultiSelectValueType[]) => handleSource(value)}
          selectionData={sources}
          isReset={true}
          size="small"
        />
        <Typography variant="h4" sx={{ color: theme.palette.text.tableHeader, my: 1.5 }}>
          Red Flags
        </Typography>
        {/* <CustomKeywords
          onHandleChange={(keyword) => {
            handleKeywords(keyword);
          }}
        /> */}
        <CustomChipInput
          onHandleChange={(redflags) => {
            handleRedFlags(redflags);
          }}
        />
      </Box>
      <PrimaryLoadingButton
        sx={{
          ...theme.typography.hugeButton,
          width: "100%",
          my: 2,
        }}
        onClick={() => handleQuery(query)}
      >
        Query
      </PrimaryLoadingButton>
    </Box>
  );
}
